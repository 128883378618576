var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('base-material-card',{staticClass:"px-5 py-4 ml-0",attrs:{"color":"primary","icon":"mdi-flash-alert-outline","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v("ProgGeoPerdas ANEEL")])]},proxy:true}])},[(_vm.accessReleased('PROG_GEOPERDAS_ANEEL'))?_c('v-btn',{staticClass:"mt-5",staticStyle:{"float":"left"},attrs:{"color":"success","dark":"","elevation":"1","relative":"","text":"","medium":""},on:{"click":function($event){return _vm.$router.push('add')}}},[_c('v-icon',{attrs:{"left":"","size":"30px"}},[_vm._v(" mdi-plus-circle ")]),_vm._v(" Executa MT-BT ")],1):_vm._e(),_c('v-text-field',{staticClass:"ml-auto mt-4 break-search",staticStyle:{"max-width":"250px"},attrs:{"append-icon":"mdi-magnify","label":"Procurar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('general-progress-bars',{staticClass:"mt-11",attrs:{"items":_vm.downloadProgressData,"prefixText":"Baixando arquivo ..."}}),_c('v-divider',{staticClass:"mt-10"}),_c('v-data-table',{attrs:{"headers":[
        {
          text: '#',
          value: 'id'
        },
        {
          text: 'Título',
          value: 'nome'
        },
        {
          text: 'Servidor',
          value: 'servidor'
        },
        {
          text: 'Mês dos dados',
          value: 'data_registro'
        },
        {
          text: 'CTMTS',
          value: 'ctmts_selecionados'
        },
        {
          text: 'Falhas',
          value: 'numero_ctmts_com_status_falha'
        },
        {
          text: 'Sucessos',
          value: 'numero_ctmts_com_status_sucesso'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          sortable: false,
          text: 'Ações',
          value: 'actions',
          class: 'pl-4'
        }
      ],"items":_vm.items,"search":_vm.search,"mobile-breakpoint":800,"loading":_vm.loading,"hide-default-header":_vm.loading,"hide-default-footer":_vm.loading,"no-data-text":"Nenhum cálculo do ProgGeoPerdas encontrado"},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.data_registro",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatToMonth")(item.data_registro))+" v"+_vm._s(item.versao)+" ")]}},{key:"item.servidor",fn:function(ref){
      var item = ref.item;
return [_c('div',{attrs:{"title":("Host: " + (item.host) + " Port: " + (item.port))}},[_vm._v(" "+_vm._s(item.servidor)+" ")]),_c('div',[_vm._v(_vm._s(item.database))])]}},{key:"item.ctmts_selecionados",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"clickable-item",on:{"click":function($event){return _vm.openDialogCtmtsSelecionados(item.ctmts_selecionados)}}},[_vm._v(" "+_vm._s(_vm._f("exibeResumoCtmtsSelecionados")(item.ctmts_selecionados))+" ")])]}},{key:"item.numero_ctmts_com_status_falha",fn:function(ref){
      var item = ref.item;
return [_c('div',{style:({ color: 'red' })},[_vm._v(" "+_vm._s(item.numero_ctmts_com_status_falha)+" "),(
              _vm.calculaPorcentagem(
                item.numero_ctmts_com_status_falha,
                item.numero_ctmts_selecionados
              ) > 0
            )?_c('span',[_vm._v(" ("+_vm._s(_vm._f("parseNumberToFloatBR")(_vm.calculaPorcentagem( item.numero_ctmts_com_status_falha, item.numero_ctmts_selecionados )))+"%) ")]):_vm._e()])]}},{key:"item.numero_ctmts_com_status_sucesso",fn:function(ref){
            var item = ref.item;
return [_c('div',{style:({ color: 'green' })},[_vm._v(" "+_vm._s(item.numero_ctmts_com_status_sucesso)+" "),(
              _vm.calculaPorcentagem(
                item.numero_ctmts_com_status_sucesso,
                item.numero_ctmts_selecionados
              ) > 0
            )?_c('span',[_vm._v(" ("+_vm._s(_vm._f("parseNumberToFloatBR")(_vm.calculaPorcentagem( item.numero_ctmts_com_status_sucesso, item.numero_ctmts_selecionados )))+"%) ")]):_vm._e()])]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c('general-status',{attrs:{"status":item.status}})]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{staticClass:"px-1",class:!['SUCESSO', 'FALHA'].includes(item.status) ? 'cursor-block' : '',attrs:{"min-width":"0","fab":"","icon":"","x-small":"","loading":_vm.downloadProgressData[item.id] !== undefined ? true : false,"disabled":!['SUCESSO', 'FALHA'].includes(item.status)},on:{"click":function () { return _vm.baixarArquivoResultadoCalculoPerdas(item); }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-download ")])],1),(_vm.accessReleased('PROG_GEOPERDAS_ANEEL_LOGS'))?_c('v-btn',{staticClass:"px-1",attrs:{"min-width":"0","fab":"","icon":"","x-small":""},on:{"click":function($event){return _vm.$router.push(("logs/" + (item.id)))}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-file-tree ")])],1):_vm._e(),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"id":"action-more","color":"white","height":"22px","width":"22px","fab":"","x-small":"","elevation":"1"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',[(_vm.canDeleteItem(item))?_c('v-list-item',{on:{"click":function($event){return _vm.openDialogDelete(item)}}},[_c('v-list-item-icon',{staticClass:"mx-0 my-0 py-4"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-trash-can-outline")])],1),_c('v-list-item-title',[_vm._v(" Deletar ")])],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.openDialogCreationLog(item)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-table-search ")]),_vm._v(" Log ")],1)],1)],1)],1)]}},{key:"footer.page-text",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('rechargeTable')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-refresh ")])]}}])},[_c('span',[_vm._v(" Clique aqui para recarregar as execuções ")])])]},proxy:true}],null,true)})],1),_c('dialog-creation-log',{attrs:{"dialog":_vm.dialogCreationLog,"item":_vm.dialogCreationLogData},on:{"update:dialog":function($event){_vm.dialogCreationLog=$event}}}),_c('v-dialog',{attrs:{"scrollable":"","max-width":"500px"},model:{value:(_vm.dialogCtmtsSelecionados),callback:function ($$v) {_vm.dialogCtmtsSelecionados=$$v},expression:"dialogCtmtsSelecionados"}},[_c('v-card',[_c('v-card-title',{staticClass:"py-3",staticStyle:{"text-align":"left !important"}},[_c('h2',[_vm._v("CTMTS selecionados")])]),_c('v-divider'),_c('v-card-text',{staticClass:"py-3"},[_c('v-textarea',{staticClass:"ta-padding-c",attrs:{"rows":"10","no-resize":"","background-color":"#EEEEEE","readonly":true,"value":_vm.dialogCtmtsSelecionadosData.join('\n')}})],1),_c('v-divider'),_c('v-card-actions',{staticStyle:{"text-align":"right !important","display":"block !important"}},[_c('v-btn',{attrs:{"color":"green darken-1"},on:{"click":function($event){_vm.dialogCtmtsSelecionados = false}}},[_vm._v(" OK ")])],1)],1)],1),_c('dialog-delete',{attrs:{"dialog":_vm.dialogDelete},on:{"update:dialog":function($event){_vm.dialogDelete=$event},"submitTriggered":function($event){return _vm.deletarItem(_vm.dialogDeleteData.id)}}},[_c('template',{slot:"title"},[_vm._v(" Deletar Lote #"+_vm._s(_vm.dialogDeleteData.id)+" ")]),_c('template',{slot:"body"},[_vm._v(" Tem certeza que deseja deletar os cálculos do lote "),_c('strong',[_vm._v(" #"+_vm._s(_vm.dialogDeleteData.id)+" ")]),_vm._v("? ")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }